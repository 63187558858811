import React from 'react';
import { useMediaQuery } from 'react-responsive';
// import styles from './Footer.module.scss';



const Footer = () => {
  const isHuge = useMediaQuery({ query: '(min-width: 1900px)' })
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })

  return (
    <div className={isHuge ? 'mx-48' : ''}>
      <footer className={`bottom-0 left-0 z-20 p-4 mt-3 ${isTiny ? 'mx-1' : 'mx-10'} bg-neutral-900/80 text-white shadow-xl rounded-t-md`}>
        <div className='flex justify-center'>
          <a className='text-neutral-200/30 hover:text-neutral-200' href="https://portfolio.edgarsapinis.dev/" target='_blank'><b>portfolio.edgarsapinis.dev</b></a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
