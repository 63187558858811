import React, { useState } from 'react';
import Loader from '../../components/loader/Loader';

import { auth } from '../../firebase/config';
import { sendPasswordResetEmail } from 'firebase/auth';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Reset = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);

    sendPasswordResetEmail(auth, email)
    .then(() => {
      toast.success('Password reset email sent');
      setIsLoading(false);
    })
    .catch((error) => {
      //const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(errorMessage);
      setIsLoading(false);
    });
  }

  return (
    <>
    <ToastContainer />
    {isLoading ? <Loader></Loader> : null}
    <div className='minheight pt-10'>
      <section className=''>
        <div className='m-6 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0'>

          <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-neutral-900/90 border-neutral-700'>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
              <h1 className='text-xl font-bold leading-tight tracking-tight md:text-2xl text-white'>
                Reset Password
              </h1>

              <form onSubmit={resetPassword} className='space-y-4 md:space-y-6'>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
                  <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="name@email.com" required />
                </div>

                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Reset Password</button>

              </form>

            </div>
          </div>

        </div>
      </section>
    </div>
    </>
  );
}

export default Reset;
