import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StarsRating from 'react-star-rate';

import useFetchCollection from '../../../customHooks/useFetchCollection';
import useFetchDocument from '../../../customHooks/useFetchDocument';

import { ADD_TO_CART, CALCULATE_SUMMARY } from '../../../redux/slice/cartSlice';
import Loader from '../../loader/Loader';
import style from './ProductDetails.module.scss';


const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [size, setSize] = useState('S');
  const { document, isLoading } = useFetchDocument('products', id);
  const isMobile = useMediaQuery({ query: '(max-width: 820px)' })
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })

  const {data} = useFetchCollection('reviews'); // For the reviews section
  const filteredReviews = data.filter(review => review.productID === id); // For the reviews section


  useEffect(() => {
    setProduct(document);
  }, [document])


  const getSize = (size) => {
    setSize(size);
  }


  const addToCart = (product) => {
    dispatch(ADD_TO_CART({...product, size}));
    dispatch(CALCULATE_SUMMARY());
  }

  const buyNow = (product) => {
    dispatch(ADD_TO_CART({...product, size}));
    dispatch(CALCULATE_SUMMARY());
    navigate('/cart');
  }

  // For scrolling to top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
  <div className='minheight'>
    {isLoading ? (<Loader></Loader> ) 
    :
    (
    
    <div className={`bg-neutral-900/0 ${isTiny? 'mx-0' : 'mx-10'} mb-10 pb-10 rounded-md`}>

      <div className="flex flex-col flex-wrap items-center justify-center p-2">
        <div className={style.custwidth}>
          
          <button className="h-10 px-4 font-semibold rounded-md border-slate-200 text-neutral-100 bg-neutral-900/90 hover:bg-neutral-900" type="button">
                <Link to='/#products'>
                  Back To Products
                </Link>
          </button>

        <div className={isMobile ? 'flex-col font-sans bg-neutral-900/95 rounded-md mt-2 drop-shadow-xl' : 'flex font-sans bg-neutral-900/95 rounded-md mt-2 drop-shadow-xl'}>

          <div className={isMobile ? 'flex-none w-full relative' : 'flex-none w-2/4 relative'}>
            <img src={product.imageURL} alt={product.name} className={isMobile ? "inset-0 object-cover rounded-md" : "inset-0 object-cover rounded-l-md"} /*loading="lazy"*/ />
          </div>

          <form className="flex-auto p-6">
            <div className="flex flex-wrap">
              <h1 className="flex-auto text-lg font-semibold text-white">
                {product.name}
              </h1>
              <div className="text-lg font-semibold text-white">
                {product.price}€
              </div>
              <div className="w-full flex-none text-sm font-medium text-white mt-2">
                In stock
              </div>
            </div>

            <div className="flex justify-center items-baseline mt-4 mb-6 pb-6 border-b border-slate-200">

              {product.category === 'Hats' ? 
              (<div>

                <div className="space-x-2 flex text-sm">
                  <label>
                    <input className="sr-only peer" name="size" type="radio" defaultValue="osfa" defaultChecked />
                    <div onClick={() => getSize('OSFA')} className="w-28 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2">
                      One Size Fits All
                    </div>
                  </label>
                </div>

                <div className='flex justify-center items-baseline mt-4 mb-6 pb-6 border-b border-slate-200'></div>
                <p className='text-white'>{product.description}</p>


              </div>
              ) : (
              <div>

              <div className="space-x-2 flex text-sm">
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="s" defaultChecked />
                  <div onClick={() => getSize('S')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    S
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="m" />
                  <div onClick={() => getSize('M')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    M
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="l" />
                  <div onClick={() => getSize('L')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    L
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="xl" />
                  <div onClick={() => getSize('XL')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    XL
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="2xl" />
                  <div onClick={() => getSize('2XL')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    2XL
                  </div>
                </label>
              </div>


              <div className='flex justify-center items-baseline mt-4 mb-6 pb-6 border-b border-slate-200'></div>
              <p className='text-white'>{product.description}</p>

              </div>)}

            </div>


            <div className="flex space-x-4 mb-4 text-sm font-medium">
              <div className="flex-auto flex space-x-4">
                <button onClick={() => buyNow(product)} className="h-10 px-4 font-semibold rounded-md bg-neutral-100 text-neutral-900" type="button">
                  Buy now
                </button>
                <button onClick={() => addToCart(product)} className="h-10 px-4 font-semibold rounded-md border border-slate-200 text-neutral-100" type="button">
                  
                  Add to Cart
                </button>
              </div>
            </div>

          </form>
        </div>



        <div className='bg-neutral-900/90 w-full mt-2 rounded-md text-white'>
          {filteredReviews.length === 0 ? <div className='text-center h-40 pt-16'><b>No reviews yet</b></div> :
          <div>
            {filteredReviews.map((reviews, index) => {
              const {rate, review, reviewDate, userName } = reviews
              return(
                <div key={index} className='flex flex-col rounded-md justify-center text-center items-center bg-neutral-900/90 pb-4 px-10 m-2'>
                  <StarsRating disabled value={rate}/>
                  <p><b>{userName} - {reviewDate}</b></p>
                  <p>{review}</p>
                </div>
              )
              })}

          </div>}
        </div>

      </div>
    </div>
    </div>
    
    )}

  </div>
  );
}

export default ProductDetails;
