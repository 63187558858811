import { addDoc, collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { db, storage } from '../../../firebase/config';
import Loader from '../../loader/Loader';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../../redux/slice/productSlice';

const categories = [
  { id: 1, name: 'Hoodies' },
  { id: 2, name: 'T-Shirts' },
  { id: 3, name: 'Hats' },
];

const initialState = {
  name: '',
  imageURL: '',
  price: 0,
  category: '',
  brand: '',
  description: '',
};



const AddProduct = () => {
  const { id } = useParams(); // For editing items
  
  const allProducts = useSelector(selectProducts); // For editing items
  const productEdit = allProducts.find((item) => item.id === id); // For editing items


  const [products, setProducts] = useState(() => {
    const newState = detectForm(
      id,
      {...initialState},
      productEdit
      )
      return newState;
  });

  const [uploadProgress , setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  function detectForm(id, f1, f2){ //For editing or adding items
    if (id === 'ADD') {
      return f1;
    }
    else {
      return f2;
    }
  }


  const handleInputChange = (e) => {
    const {name, value} = e.target
    setProducts({...products, [name]: value})
  }


  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const storageRef = ref(storage, `portfolio-shop/${Date.now()}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);

      },
      (error) => {
        toast.error(error.message);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setProducts({...products, imageURL: downloadURL});
          toast.success('Image uploaded successfully');
        });
      }
    );
  }


  const addProduct = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // NOT WORKING
    // if (products.imageURL !== productEdit.imageURL) { // If image is uploaded then delete previous image
    //   const storageRef = ref(storage, productEdit.imageURL);
    //   deleteObject(storageRef);
    // }

    try {
      const docRef = addDoc(collection(db, "products"), {
        name: products.name,
        imageURL: products.imageURL,
        price: Number(products.price),
        category: products.category,
        brand: products.brand,
        description: products.description,
        createdAt: Timestamp.now().toDate().toString(),
      });

      setIsLoading(false);
      setProducts({...initialState});
      setUploadProgress(0);

      toast.success('Product added successfully');
    }
    catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }


  const editProduct = (e) => {
    e.preventDefault();
    setIsLoading(true);
    try{

      setDoc(doc(db, "products", id), {
        name: products.name,
        imageURL: products.imageURL,
        price: Number(products.price),
        category: products.category,
        brand: products.brand,
        description: products.description,
        createdAt: productEdit.createdAt,
      });
      setIsLoading(false);
      toast.success('Product edited successfully');

    }
    catch(error){
      toast.error(error.message);
      setIsLoading(false);
    }
  }
  

  return (
    <>
      {isLoading ? <Loader></Loader> : null}
    <div>
      <section className='bg-gray-50 dark:bg-neutral-900 py-10'>
        <div className='flex flex-col items-center px-6 mx-auto md:h-auto lg:py-0'>

          <div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-neutral-800 dark:border-neutral-700'>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
              <h2 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
                {detectForm(id, 'Add Product', 'Edit Product')}
              </h2>

              <form onSubmit={detectForm(id, addProduct, editProduct)} className='space-y-4 md:space-y-6'>
                
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Name</label>
                  <input placeholder="Product Name" type="text" required name="name" value={products.name} onChange={(e) => handleInputChange(e)}  className="bg-gray-50 border border-neutral-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Image: Use 1200x1600px</label>
                  {uploadProgress === 0 ? null : <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Progress: {uploadProgress}%</label>}
                  <input placeholder="Product Image" type="file" accept='image/*' name="image" onChange={(e) => handleImageChange(e)}  className="bg-gray-50 border border-neutral-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                  <input placeholder="www.firebase.com" type="text" /*required*/ name="imageURL" value={products.imageURL} disabled className="my-2 bg-gray-50 border border-neutral-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Price</label>
                  <input placeholder="Product Price" type="number" required name="price" value={products.price} onChange={(e) => handleInputChange(e)}  className="bg-gray-50 border border-neutral-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Category</label>
                  <select required name="category" value={products.category} onChange={(e) => handleInputChange(e)}>
                    <option value="" disabled>--Chose Product Category--</option>
                    {categories.map((cat) => {
                      return(
                        <option key={cat.id} value={cat.name}>{cat.name}</option>
                      )
                      })}
                  </select>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Brand/Collection</label>
                  <input placeholder="Product Brand/Collection" type="text" required name="brand" value={products.brand} onChange={(e) => handleInputChange(e)}  className="bg-gray-50 border border-neutral-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Description</label>
                  <textarea name="description" value={products.description}  onChange={(e) => handleInputChange(e)} cols="30" rows="10"></textarea>
                </div>

                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  {detectForm(id, 'Save', 'Edit')}
                </button>

              </form>

            </div>
          </div>

        </div>
      </section>

    </div>
    </>
  );
}

export default AddProduct;
