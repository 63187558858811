import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { ADD_TO_CART, CALCULATE_SUMMARY } from '../../../redux/slice/cartSlice';
import style from './ProductItem.module.scss';


const ProductItem = ({product, id, name, price, description, imageURL, brand, category}) => {
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [size, setSize] = useState('S');

  const getSize = (size) => {
    setSize(size);
  }

  const addToCart = (product) => {
    dispatch(ADD_TO_CART({...product, size}));
    dispatch(CALCULATE_SUMMARY());
  }

  const buyNow = (product) => {
    dispatch(ADD_TO_CART({...product, size}));
    dispatch(CALCULATE_SUMMARY());
    navigate('/cart');
  }

  return (

    <div className={style.custwidth}>

      <div className="flex font-sans bg-neutral-900/80 hover:bg-neutral-900 rounded-md m-2 drop-shadow-xl">

        <div className={`flex-none ${isTiny ? 'w-40' : 'w-48'} relative`}>
          <Link to={`/product-details/${id}`}>
          <img src={imageURL} alt={name} className="absolute inset-0 w-full h-full object-cover rounded-l-md"/>
          </Link>
        </div>

        <form className={`flex-auto ${isTiny ? 'p-4' : 'p-6'}`}>
          <div className="flex flex-wrap">
            <h1 className="flex-auto text-lg font-semibold text-white">
              {name}
            </h1>
            <div className="text-lg font-semibold text-white">
              {price}€
            </div>
            <div className="w-full flex-none text-sm font-medium text-white mt-2">
              In stock
            </div>
          </div>
          <div className="flex justify-center items-baseline mt-4 mb-6 pb-6 border-b border-slate-200">
            

            {category === 'Hats' ? 
            (<div>

              <div className="space-x-2 flex-start text-sm">
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="s" defaultChecked />
                  <div onClick={() => getSize('OSFA')} className="mx-10 w-28 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2">
                    One Size Fits All
                  </div>
                </label>
              </div>


            </div>
            ) : (
            <div>

              <div className={`${isTiny? 'space-x-1' : 'space-x-2'} flex text-sm`}>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="s" defaultChecked />
                  <div onClick={() => getSize('S')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    S
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="m" />
                  <div onClick={() => getSize('M')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    M
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="l" />
                  <div onClick={() => getSize('L')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    L
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="xl" />
                  <div onClick={() => getSize('XL')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    XL
                  </div>
                </label>
                <label>
                  <input className="sr-only peer" name="size" type="radio" defaultValue="2xl" />
                  <div onClick={() => getSize('2XL')} className="w-9 h-9 rounded-lg flex items-center justify-center text-neutral-100 peer-checked:font-semibold peer-checked:bg-neutral-100 peer-checked:text-neutral-900 hover:border-2 cursor-pointer">
                    2XL
                  </div>
                </label>
              </div>

            </div>)}

          </div>
          <div className="flex space-x-4 mb-4 text-sm font-medium">
            <div className="flex-auto flex space-x-4">
              <button onClick={() => buyNow(product)} className="h-10 px-4 font-semibold rounded-md bg-neutral-100 text-neutral-900" type="button">
                Buy now
              </button>
              <button onClick={() => addToCart(product)} className="h-10 px-4 font-semibold rounded-md border border-slate-200 text-neutral-100" type="button">
                Add to Cart
              </button>
            </div>
          </div>
          <p className="text-sm text-neutral-400">
            {brand}
          </p>
        </form>
      </div>

    </div>
    
  );
}

export default ProductItem;
