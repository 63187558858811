import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import useFetchDocument from '../../customHooks/useFetchDocument';


const OrderDetails = () => {
  const isHuge = useMediaQuery({ query: '(min-width: 1900px)' })
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  const { document, isLoading } = useFetchDocument('orders', id);

  useEffect(() => {
    setOrder(document);
  }, [document]);



  return (
    <div className={isHuge ? 'mx-48' : ''}>
      <div className={`minheight bg-neutral-900/90 rounded-md ${isTiny? 'mx-1' : 'mx-10'}`}>
        {isLoading ? <Loader/> : 
        <div>
          <div className='flex flex-col justify-center p-4'>
            <h1 className='text-3xl font-semibold text-white'>Order details</h1>
            <p className='text-white'><b>Order ID:</b> {order.id}</p>
            <p className='text-white'><b>Order Amount:</b> {order.orderAmount}€</p>
            <p className='text-white'><b>Order Status:</b> {order.orderStatus}</p>

          </div>

          <div className='flex flex-wrap'>
            {order.cartItems?.map((item, index) => {
            const { id, name, price, size, imageURL, cartQuantity } = item;
            
            return (
              
              <div key={index} className='w-96 bg-neutral-900 m-4 flex text-white hover:bg-neutral-900 rounded-md'>
                <img className='w-40 rounded-l-md' src={imageURL} alt={name} ></img>
                <div className='flex flex-col'>
                  <p className='m-2'><b>Name: </b>{name}</p>
                  <p className='m-2'><b>Size: </b>{size}</p>
                  <p className='m-2'><b>Price: </b>{price}€</p>
                  <p className='m-2'><b>Quantity: </b>{cartQuantity}</p>
                  <Link to={`/review-product/${id}`}>
                    <button className='bg-white hover:bg-neutral-300 text-black rounded-md m-2 p-2'><b>Review</b></button>
                  </Link>
                </div>
              </div>
            )
            })}
          </div>

        </div>}
      </div>
    </div>
  );
}

export default OrderDetails;
