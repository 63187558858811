import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Loader.module.scss';

const Loader = () => {
  return ReactDOM.createPortal (

    <div className={styles.wrapper}>
      <div className={styles.ldsellipsis}><div></div><div></div><div></div><div></div></div>
    </div>,

    document.getElementById('loader')
  );
}

export default Loader;
