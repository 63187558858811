import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';


import { FaShoppingCart } from 'react-icons/fa';
import { IoPersonCircleSharp } from 'react-icons/io5';
import { IoMenu } from 'react-icons/io5';
import { BiLogOut, BiMessageAltDetail } from 'react-icons/bi';

import { auth } from '../../firebase/config';
import { onAuthStateChanged, signOut } from 'firebase/auth';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { REMOVE_ACTIVE_USER, SET_ACTIVE_USER } from '../../redux/slice/authSlice';
import ShowOnLogin, { ShowOnLogout } from '../hiddenLink/HiddenLink';
import { AdminOnlyLink } from '../../adminOnlyRoute/AdminOnlyRoute';

import { CALCULATE_SUMMARY, selectCartTotalQuantity } from '../../redux/slice/cartSlice';




const Header = () => {
  const dispatch = useDispatch();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const navigate = useNavigate();


  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  useEffect(() => {
    dispatch(CALCULATE_SUMMARY());
  }, [cartTotalQuantity, dispatch])


  const isMobile = useMediaQuery({ query: '(max-width: 820px)' })
  const isHuge = useMediaQuery({ query: '(min-width: 1900px)' })
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })


  // Monitor sign in user
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {

        if (user.displayName === null) {
          const u1 = user.email.split('@');
          setDisplayName(u1[0]);
        } else{
          setDisplayName(user.displayName);
        }

        dispatch(SET_ACTIVE_USER({
          email: user.email,
          userName: user.displayName ? user.displayName : user.email.split('@')[0],
          userID: user.uid,
        }));


      } else {
        setDisplayName('');
        dispatch(REMOVE_ACTIVE_USER());
      }
    });
  }, [dispatch]);


  const handleMenu = () => {
    setToggleMenu(!toggleMenu);
  }

  const hideMenu = () => {
    setToggleMenu(false);
  }


  const logoutUser = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      navigate('/');
    }).catch((error) => {
      // An error happened.
      toast.error(error.message);
    });
  }







  const profileAndCart = (
  <div>
    
    <AdminOnlyLink>
      <Link to='/admin/order'>
        <button className='relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 rounded-lg hover:bg-neutral-900 mx-1'>
          <IoPersonCircleSharp size={22}/>
          <p>Admin</p>
        </button>
      </Link>
    </AdminOnlyLink>


    <ShowOnLogin>
      <Link to='/' onClick={logoutUser}>
        <button className='relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 rounded-lg hover:bg-neutral-900 mx-1'>
          <BiLogOut size={22}/>
        </button>
      </Link>
    </ShowOnLogin>

    <ShowOnLogin>
      <Link to='/order-history'>
        <button className='relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 rounded-lg hover:bg-neutral-900 mx-1'>
          <IoPersonCircleSharp size={22}/>
          <p>{displayName}</p>
        </button>
      </Link>
    </ShowOnLogin>

    <ShowOnLogout>
      <Link to='/login'>
        <button className='relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 rounded-lg hover:bg-neutral-900 mx-1'>
          <IoPersonCircleSharp size={22}/>
        </button>
      </Link>
    </ShowOnLogout>

    <Link to='/cart'>
      <button className='relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 rounded-lg hover:bg-neutral-900 mx-1'>
        <FaShoppingCart size={22}/>
        <p></p>
        <div className='absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-600 rounded-full -top-2 -right-2 '>
          {cartTotalQuantity}
        </div>
      </button>
    </Link>


  </div>
  )


  return (

  <>
    <ToastContainer />
    <div>
      {isMobile ?
      // MOBILE NAVBAR
      <div>
        <header className={`header top-0 flex items-center justify-between ${isTiny ? 'mx-1' : 'mx-8'} py-10`}>

        <div>
          <button onClick={() => handleMenu()} className='relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 hover:bg-neutral-900 rounded-lg mx-2'>
            <IoMenu size={22}/>
          </button>
        </div>

        {profileAndCart}

        </header>

        {toggleMenu ? 
        <div onClick={() => hideMenu()} className='text-sm font-medium text-white top-0 bg-neutral-900/50 rounded-md shadow-xl flex flex-col items-center justify-center px-8 p-5 mb-8 mx-10'>
          <Link to='/'>
            <p className='bg-neutral-900/80 hover:bg-neutral-900 text-center m-1 p-3 w-80 rounded-md'>Home</p>
          </Link>

          <Link to='/contact'>
            <p className='bg-neutral-900/80 hover:bg-neutral-900 text-center m-1 p-3 w-80 rounded-md'>Contact Us</p>
          </Link>

        </div> : null}

      </div>


      : 
      // DESKTOP NAVBAR
      <div className={isHuge ? 'mx-48' : ''}>
        <header className=' text-white z-10 w-full flex items-center justify-between my-10 px-10 '>

          <Link to='/'>
          <div style={{ fontFamily: 'Raleway', fontSize: 35  }} className='m-0 p-0'>
            <p>shop.edgarsapinis.dev</p>
          </div>
          </Link>

          <div className='flex'>

            <Link to='/contact'>
              <button className='relative inline-flex items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 rounded-lg hover:bg-neutral-900 mx-1'>
                <BiMessageAltDetail size={22}/>
              </button>
            </Link>

            {profileAndCart}
          </div>
          
        
        </header>
      </div>}

    </div>
  </>
  );
}

export default Header;
