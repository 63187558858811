import { doc, setDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { db } from '../../../firebase/config';
import Loader from '../../loader/Loader';

const ChangeOrderStatus = ({order, id}) => {
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const editOrder = (e, id) => {
    e.preventDefault();
    setIsLoading(true);

    const orderConfig = {
      userID: order.userID,
      customerEmail: order.customerEmail,
      orderDate: order.orderDate,
      orderTime: order.orderTime,
      orderAmount: order.orderAmount,
      orderStatus: status,
      cartItems: order.cartItems,
      shippingAddress: order.shippingAddress,
      createdAt: order.createdAt,
    }

    try {
      setDoc(doc(db, "orders", id), orderConfig);
      toast.success("Order status changed!");
      setIsLoading(false);
      navigate("/admin/order");
    }
    catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }


  return (
    <div>
      {isLoading ? <Loader></Loader> :
      <div className='my-1 p-1 border flex justify-center'>
        
        <form onSubmit={(e) => editOrder(e, id)}>
          <select className='p-1' value={status} onChange={(e) => setStatus(e.target.value)}>
            <option disabled value=''>Select Status</option>
            <option value='Order Placed'>Order Placed</option>
            <option value='Shipped'>Shipped</option>
            <option value='Delivered'>Completed</option>
          </select>
          <button className='bg-blue-500 hover:bg-blue-700 text-white ml-1 p-1' type='submit'>Change Status</button>
        </form>

      </div>}
    </div>
  );
}

export default ChangeOrderStatus;
