import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  // LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail, selectUserID } from "../../redux/slice/authSlice";
import { CLEAR_CART, selectCartItems, selectCartTotalAmount } from "../../redux/slice/cartSlice";
import { selectShippingAddress } from "../../redux/slice/checkoutSlice";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../../firebase/config";
import { useNavigate } from "react-router-dom";


  const CheckoutForm = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userID = useSelector(selectUserID);
  const customerEmail = useSelector(selectEmail);
  const cartItems = useSelector(selectCartItems);
  const shippingAddress = useSelector(selectShippingAddress);
  const cartTotalAmount = useSelector(selectCartTotalAmount);


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  }, [stripe]);




  const saveOrder = () => {
    const today = new Date();
    const date = today.toDateString();
    const time = today.toLocaleTimeString();
    const orderConfig = {
      userID: userID,
      customerEmail: customerEmail,
      orderDate: date,
      orderTime: time,
      orderAmount: cartTotalAmount,
      orderStatus: "Order Placed",
      cartItems: cartItems,
      shippingAddress: shippingAddress,
      createdAt: Timestamp.now().toDate().toString(),
    }

    try {
      addDoc(collection(db, "orders"), orderConfig);
      dispatch(CLEAR_CART()); // Clear cart
      toast.success("Order saved successfully!");
      navigate("/checkout-success");
    }
    catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  }




  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const confirmPayment = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000/checkout-success",
      },
      redirect: 'if_required'
    })
    .then ((result) => {
      // ok - payment succeeded // bad - error
      if (result.error) {
        toast.error(result.error.message);
        setMessage(result.error.message);
        return;
      }
      if (result.paymentIntent) {
        if (result.paymentIntent.status === "succeeded") {
          setIsLoading(false);
          toast.success("Payment succeeded!");
          saveOrder();
          // Redirect to success page
          
        }
      }
    });
    setIsLoading(false);
  };


  const paymentElementOptions = {
    layout: "tabs"
  }



  return (
    <div className='minheight pt-10'>
      <div className="m-6 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0">
        <div className='w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0'>

          <form onSubmit={handleSubmit}>

            <div className="bg-white rounded-md p-4">
              <div>
                {/* <h3>Stripe Checkout</h3> */}

                <PaymentElement options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id="submit" className="p-3 mt-4 border rounded-md w-full hover:bg-gray-200">
                  <span id="button-text">
                    {isLoading ? 'Lūdzu uzgaidiet' : "Turpināt"}
                  </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div>{message}</div>}

              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default CheckoutForm;
