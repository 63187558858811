import React, { useEffect } from 'react';
import Product from '../../components/product/Product';

const Home = () => {

  const url = window.location.href;
  const scrollToProducts = () => {
    if (url.includes('#products')){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      return;
    }
  }

  useEffect(() => {
    scrollToProducts();
  }, []);


  return (
    <div className='minheight'>

      <div>
        <Product/>
      </div>
      
    </div>
  );
}

export default Home;
