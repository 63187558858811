import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  return (
    <div className='bg-gray-50 dark:bg-neutral-800 h-12 flex place-content-center '>
      
      <div>
        <NavLink to='/admin/order'>
        <button className='py-3 px-5 bg-blue-600 text-white hover:bg-blue-700'>Orders</button>
        </NavLink>
      </div>

      <div>
        <NavLink to='/admin/all-products'>
        <button className='py-3 px-5 bg-blue-600 text-white hover:bg-blue-700'>View Products</button>
        </NavLink>
      </div>
      
      <div>
        <NavLink to='/admin/add-product/ADD'>
        <button className='py-3 px-5 bg-blue-600 text-white hover:bg-blue-700'>Add Products</button>
        </NavLink>
      </div>

    </div>
  );
}

export default Navbar;
