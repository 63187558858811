import React from 'react';

const Search = ({value, onChange}) => {
  return (
  <form className="flex items-center">   
    <label htmlFor="simple-search" className="sr-only">Search</label>
    <div className="drop-shadow-xl relative w-full">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg aria-hidden="true" className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" /></svg>
      </div>
      <input value={value} onChange={onChange} type="text" className="text-sm rounded-lg block w-full pl-10 p-3 bg-neutral-900/80 hover:bg-neutral-900 border-neutral-600 placeholder-white text-white focus:ring-neutral-500 focus:border-neutral-500" placeholder="Search" required />
    </div>
  </form>
  );
}

export default Search;
