import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredProducts: [],
}

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    FILTER_BY_SEARCH: (state, action) => {
      const { products, search } = action.payload;

      //console.log('products: ' + products);
      //console.log('search: ' + search);

      const tempProducts = products.filter((products) => products.name.toLowerCase().includes(search.toLowerCase())
      || products.brand.toLowerCase().includes(search.toLowerCase())
      || products.description.toLowerCase().includes(search.toLowerCase())
      || products.category.toLowerCase().includes(search.toLowerCase()));
      state.filteredProducts = tempProducts;
      //console.log('tempProducts: ' + tempProducts);
      //console.log('state.filteredProducts: ' + state.filteredProducts);
    },

    SORT_PRODUCTS: (state, action) => {
      const { products, sort } = action.payload;

      let tempProducts = [...products];
      if (sort === 'latest') {
        tempProducts = products;
      }
      if (sort === 'lowest-price') {
        tempProducts.sort((a, b) => a.price - b.price);
      }
      if (sort === 'highest-price') {
        tempProducts.sort((a, b) => b.price - a.price);
      }

      state.filteredProducts = tempProducts;
    },


    FILTER_BY_CATEGORY: (state, action) => {
      //console.log(action.payload);
      const { products, category } = action.payload;

      let tempProducts = [...products]
      if (category === 'All') {
        tempProducts = products;
      }
      else {
        tempProducts = products.filter((product) => product.category === category);
      }
      state.filteredProducts = tempProducts;
    },


    FILTER_BY_BRAND: (state, action) => {
      //console.log(action.payload);
      const { products, brand } = action.payload;

      let tempProducts = [...products]
      if (brand === 'All') {
        tempProducts = products;
      }
      else {
        tempProducts = products.filter((product) => product.brand === brand);
      }
      state.filteredProducts = tempProducts;
    }
  
  }
})

export const { FILTER_BY_SEARCH, SORT_PRODUCTS, FILTER_BY_CATEGORY, FILTER_BY_BRAND } = filterSlice.actions;

export const selectFilteredProducts = (state) => state.filter.filteredProducts;

export default filterSlice.reducer;