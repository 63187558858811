import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  cartItems: localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [], // Get cartItems from localStorage
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  previousURL: "",
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_TO_CART: (state, action) => {
      // If added the same prodict but the size is different, then add the new product to cart
      // If added the same product and the size is the same, then increase the quantity of the product
      if (state.cartItems.find(item => item.id === action.payload.id && item.size === action.payload.size)) { // Check if product already exists in cart
        // Increase the quantity of the product
        state.cartItems.find(item => item.id === action.payload.id && item.size === action.payload.size).cartQuantity += 1; // Increase the quantity of the product
        toast.info(`Added another ${action.payload.name} size ${action.payload.size} to cart`, {position: "top-left"}); // Show toast
      }
      else { // If product does not exist in cart
        // Add the product to cart
        const tempProduct = {...action.payload, cartQuantity: 1}; // Create a new object with quantity property
        state.cartItems.push(tempProduct); // Add the new object to cartItems array
        toast.success(`${action.payload.name} added to cart`, {position: "top-left"}); // Show toast
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems)); // Save cartItems to localStorage
    },

    DECREASE_CART: (state, action) => {
      //console.log(action.payload);
      const productIndex = state.cartItems.findIndex((item) => item.id === action.payload.id); // Check if product already exists in cart

      if (state.cartItems[productIndex].cartQuantity > 1) { // If product quantity is greater than 1
        // Decrease the quantity of the product
        state.cartItems[productIndex].cartQuantity -= 1; // Decrease the quantity of the product
      }
      else if (state.cartItems[productIndex].cartQuantity === 1) { // If product quantity is 1
        // Remove the product from cart
        state.cartItems.splice(productIndex, 1); // Remove the product from cart
        // Update local storage
      }
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems)); // Save cartItems to localStorage
    },

    REMOVE_FROM_CART: (state, action) => {
      const productIndex = state.cartItems.findIndex((item) => item.id === action.payload.id); // Check if product already exists in cart
      state.cartItems.splice(productIndex, 1); // Remove the product from cart  
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems)); // Save cartItems to localStorage
    },

    CALCULATE_SUMMARY: (state, action) => {

      // Calculate total quantity
      let totalQuantity = 0;
      state.cartItems.forEach((item) => {
        totalQuantity += item.cartQuantity;
      })
      state.cartTotalQuantity = totalQuantity;

      // Calculate total amount
      let totalAmount = 0;
      state.cartItems.forEach((item) => {
        totalAmount += item.cartQuantity * item.price;
      })
      state.cartTotalAmount = totalAmount;
    },

    SAVE_URL: (state, action) => {
      state.previousURL = action.payload;
    },

    CLEAR_CART: (state, action) => {
      state.cartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems)); // Save cartItems to localStorage
    }
  }
})

export const { ADD_TO_CART, DECREASE_CART, REMOVE_FROM_CART, CALCULATE_SUMMARY, SAVE_URL, CLEAR_CART } = cartSlice.actions;

export const selectCartItems = (state) => state.cart.cartItems;
export const selectCartTotalQuantity = (state) => state.cart.cartTotalQuantity;
export const selectCartTotalAmount = (state) => state.cart.cartTotalAmount;
export const selectPreviousURL = (state) => state.cart.previousURL;

export default cartSlice.reducer;