import { addDoc, collection, Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StarsRating from 'react-star-rate';
import { toast } from 'react-toastify';
import useFetchDocument from '../../customHooks/useFetchDocument';
import { db } from '../../firebase/config';
import { selectUserID, selectUserName } from '../../redux/slice/authSlice';
import Loader from '../loader/Loader';

const ReviewProducts = () => {
  const [rate, setRate] = useState(5);
  const [review, setReview] = useState('');
  const { id } = useParams();

  const [product, setProduct] = useState(null); // If user refreshes the page, the data will be fetched from the database again.
  const { document, isLoading } = useFetchDocument('products', id); // If user refreshes the page, the data will be fetched from the database again.

  const userID = useSelector(selectUserID);
  const userName = useSelector(selectUserName);
  const [reviewName, setReviewName] = useState(userName); // For the reviews section

  useEffect(() => {
    setProduct(document);
  }, [document]);

  const submitForm = (e) => {
    e.preventDefault();

    const today = new Date();
    const date = today.toDateString();
    const reviewConfig = {
      userID: userID,
      userName: reviewName,
      productID: id,
      rate: rate,
      review: review,
      reviewDate: date,
      createdAt: Timestamp.now().toDate().toString(),
    }

    try {
      addDoc(collection(db, "reviews"), reviewConfig);
      toast.success("Review added successfully!");

      setRate(5);
      setReview('');
    }
    catch (error) {
      toast.error(error.message);
    }
    
  }

  return (
    <div className='minheight'>
      {isLoading ? <Loader/> :

      <div className='flex justify-center'>
        <div className='w-96 bg-neutral-900/90 p-4 rounded-md text-white'>

          <div className='flex justify-center bg-neutral-800 p-2 rounded-md'>
            <div>
              <img className='w-48' src={product.imageURL} alt={product.name} />
              <p className='my-2'><b>Product name:</b> {product.name}</p>
            </div>
          </div>

          <form className='flex flex-col justify-center' onSubmit={(e) => submitForm(e)}>
            <div className='flex justify-center'>
              <StarsRating value={rate} onChange={rate => {setRate(rate);}}/>
            </div>
            <textarea placeholder='Write what you think about the product..' className='w-full bg-neutral-800 p-2 rounded-md mt-1' value={review} cols='30' rows='10' onChange={(e) => setReview(e.target.value)}></textarea>
            <input type='text' placeholder='Your name' className='w-full bg-neutral-800 p-2 rounded-md mt-2 border' value={reviewName} onChange={(e) => setReviewName(e.target.value)} />
            <button type='submit' className='mt-5 p-1 rounded-md bg-white hover:bg-neutral-300 text-neutral-900'><b>Submit Review</b></button>
          </form>

        </div>
      </div>}
    </div>
  );
}

export default ReviewProducts;
