import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import useFetchCollection from '../../../customHooks/useFetchCollection';
import { selectUserID } from '../../../redux/slice/authSlice';
import { selectOrderHistory, STORE_ORDERS } from '../../../redux/slice/orderSlice';
import Loader from '../../loader/Loader';

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading } = useFetchCollection('orders');
  const orders = useSelector(selectOrderHistory);

  useEffect(() => { // When the data is fetched, store it in the redux store
    dispatch(STORE_ORDERS(data));
  }, [dispatch, data]);


  const handleClick = (id) => { // Redirect to clicked order details
    navigate(`/admin/order-details/${id}`);
  }


  return (
  <div className='minheight'>
    {isLoading && <Loader/>}
      
      {orders.length === 0 ? (
        <div className='flex flex-col items-center justify-center h-screen pb-52'>
          {isLoading ? '' :
          <>
          <h1 className='text-3xl font-semibold text-white'>Your order history is empty</h1>
          <p className='text-white'>You will see your order history after checkout</p>
          </>}
        </div>
      ) : (
        
        <div className=''>
          <div className='bg-neutral-900 h-2'></div>

          <table className="bg-neutral-900/95 w-full text-white text-center">
            <thead>
              <tr className='bg-neutral-900'>
                <th className='px-4 py-3 pb-5'>Date</th>
                <th className='px-4 py-3 pb-5'>Order ID</th>
                <th className='px-4 py-3 pb-5'>Order Amount</th>
                <th className='px-4 py-3 pb-5'>Order Statuss</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => {
                const { orderDate, orderTime, id, orderAmount, orderStatus } = order;
                return (
                  <tr key={id} onClick={() => handleClick(id)} className='hover:bg-neutral-800 cursor-pointer'>
                    <td className='px-4 py-3'>
                      {orderDate} at {orderTime}
                    </td>
                    <td className='px-4 py-3'>
                      {id}
                    </td>
                    <td className='px-4 py-3'>
                      {orderAmount}€
                    </td>
                    <td className='px-4 py-3'>
                      {orderStatus}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>
      )}

  </div>
  );
}

export default Orders;
