import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { CALCULATE_SUMMARY, selectCartItems, selectCartTotalAmount } from "../../redux/slice/cartSlice";
import { selectEmail } from "../../redux/slice/authSlice";
import { selectBillingAddress, selectShippingAddress } from "../../redux/slice/checkoutSlice";
import { toast } from "react-toastify";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PRIVATE_KEY}`);


const Checkout = () => {

  const [message, setMessage] = useState('Initializing Checkout');
  const [clientSecret, setClientSecret] = useState("");

  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectCartTotalAmount);
  const customerEmail = useSelector(selectEmail);

  const shippingAddress = useSelector(selectShippingAddress);
  const billingAddress = useSelector(selectBillingAddress);

  const dispatch = useDispatch(); // If user refreshes the page, the cart will be empty. So, we need to calculate the summary again
  useEffect(() => {
    dispatch(CALCULATE_SUMMARY());
  } , [dispatch, cartItems])

  dispatch(CALCULATE_SUMMARY());
  const description = `shop.edgarsapinis.dev Payment: email: ${customerEmail}, Amount: ${totalAmount}`

  useEffect(() => {
    
    // http://localhost:4242/create-payment-intent
    // Create PaymentIntent as soon as the page loads
    fetch("https://merch-shop.herokuapp.com/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: cartItems,
        userEmail: customerEmail,
        shipping: shippingAddress,
        billing: billingAddress,
        description: description,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((json) => Promise.reject(json))
        }
      })
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => {
        setMessage('Failed to initialize Checkout');
        toast.error('Something Went Wrong');
      });
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <div className="">
        {!clientSecret && <div className='flex flex-col items-center justify-center h-screen pb-52'>
          <h1 className="text-3xl font-semibold text-white">{message}</h1>
          </div>}
      </div>

      <div>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </>
  );
}

export default Checkout;
