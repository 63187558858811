import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Header, Footer} from './components/index';
import {Home, Contact, Login, Register, Reset} from './pages/index';
import OrderHistory from './pages/orderHistory/OrderHistory';
import Admin from './pages/admin/Admin';
import AdminOnlyRoute from './adminOnlyRoute/AdminOnlyRoute';
import ProductDetails from './components/product/productDetails/ProductDetails';
import Cart from './pages/cart/Cart';
import CheckoutDetails from './pages/checkout/CheckoutDetails';
import Checkout from './pages/checkout/Checkout';
import CheckoutSuccess from './pages/checkout/CheckoutSuccess';
import OrderDetails from './pages/orderDetails/OrderDetails';
import ReviewProducts from './components/reviewProducts/ReviewProducts';
import NotFound from './pages/notFound/NotFound';


function App() {
  return (
    <BrowserRouter>
      <Header/>

      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/register' element={<Register/>}/>
        <Route path='/reset' element={<Reset/>}/>
        <Route path='/order-history' element={<OrderHistory/>}/>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/checkout-details' element={<CheckoutDetails/>}/>
        <Route path='/checkout' element={<Checkout/>}/>
        <Route path='/checkout-success' element={<CheckoutSuccess/>}/>



        <Route path='/admin/*' element={
          <AdminOnlyRoute>
            <Admin/>
          </AdminOnlyRoute>
        }/>


        <Route path='/product-details/:id' element={<ProductDetails/>}/>
        <Route path='/order-details/:id' element={<OrderDetails/>}/>
        <Route path='/review-product/:id' element={<ReviewProducts/>}/>

        <Route path='*' element={<NotFound/>}/>
      </Routes>

      <Footer/>
    </BrowserRouter>
  );
}

export default App;
