import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddProduct from '../../components/admin/addProducts/AddProduct';
import Navbar from '../../components/admin/navbar/Navbar';
import OrderDetails from '../../components/admin/orderDetails/OrderDetails';
import Orders from '../../components/admin/orders/Orders';
import ViewProducts from '../../components/admin/viewProducts/ViewProducts';

const Admin = () => {
  return (
    <div>
      <div>
        <Navbar></Navbar>
      </div>

      <div>
        <Routes>
          <Route path='all-products' element={<ViewProducts></ViewProducts>}/>
          <Route path='add-product/:id' element={<AddProduct></AddProduct>}/>
          <Route path='order' element={<Orders></Orders>}/>
          <Route path='order-details/:id' element={<OrderDetails></OrderDetails>}/>


        </Routes>
      </div>
    </div>
  );
}

export default Admin;
