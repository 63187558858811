import { deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import Notiflix from 'notiflix';
import React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { db, storage } from '../../../firebase/config';
import Loader from '../../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectProducts, STORE_PRODUCTS } from '../../../redux/slice/productSlice';
import { Link } from 'react-router-dom';
import useFetchCollection from '../../../customHooks/useFetchCollection'; // My Custom Hook for getting data from firestore
import { FILTER_BY_SEARCH, selectFilteredProducts } from '../../../redux/slice/filterSlice';
import Search from '../../search/Search';





const ViewProducts = () => {
  const [search, setSearch] = useState('');
  const {data, isLoading} = useFetchCollection('products'); // My Custom Hook for getting data from firestore
  const products = useSelector(selectProducts);
  const filteredProducts = useSelector(selectFilteredProducts);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      STORE_PRODUCTS({
        products: data,
      })
    );
  }, [dispatch, data])


  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({products, search}));
  }, [dispatch, products, search]);


  const confirmDelete = (id, imageURL) => {
    Notiflix.Confirm.show(
      'Delete Product',
      'Are you sure you want to delete this product?',
      'Delete',
      'Cancel',
      function okCb() {
        deleteProduct(id, imageURL);
      },
      function cancelCb() {
        // do nothing
      },
      {
        width: '320px',
        borderRadius: '8px',
        titleColor: 'red',
        okButtonBackground: 'red',
      },
    );
  }


  const deleteProduct = async (id, imageURL) => {
    try {
      await deleteDoc(doc(db, "products", id));

      const storageRef = ref(storage, imageURL);
      await deleteObject(storageRef);

      toast.success('Product deleted successfully');
    }
    catch (error) {
      toast.error(error.message);
    }
  }

  
  return (
  <>
    {isLoading ? <Loader/> : null}
    <div className='bg-neutral-900 min-h-screen'>
      <div className=''>

        <div className='mb-1 mx-1'>
            <Search value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>

        {filteredProducts.length === 0 ? (
          <p className='text-neutral-700 dark:text-gray-400'>No Product Found</p>
        ) : (
          <div className="relative overflow-x-auto shadow-md ">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-neutral-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Id
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Image
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Category
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredProducts.map((product, index) => {
                    const {id, name, imageURL, price, category} = product;
                    return (
                      <tr key={id} className="bg-white border-b dark:bg-neutral-900 dark:border-gray-700">
                        <td className="px-6 py-3">
                          {index + 1}
                        </td>

                        <td className="px-6 py-3 w-36">
                          <img src={imageURL} alt={name} />
                        </td>

                        <td className="px-6 py-3">
                          {name}
                        </td>

                        <td className="px-6 py-3">
                          {category}
                        </td>

                        <td className="px-6 py-3">
                          {`${price}€`}
                        </td>

                        <td className="px-6 py-3">
                          <Link to={`/admin/add-product/${id}`}>
                          <button className="bg-green-600 hover:bg-green-700 text-white px-2 py-1 rounded-md m-2">Edit</button>
                          </Link>
                          <button onClick={() => confirmDelete(id, imageURL)} className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-md">Delete</button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
            </table>
          </div>
        )}

      </div>
    </div>
  </>
  );
}



export default ViewProducts;
