import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

const Contact = () => {
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(`${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, 'template_7sfykal', form.current, 'UtRfIiBlC7WRGl0Dl')
      .then((result) => {
        toast.success('Message sent successfully');
        console.log(result.text);
      }, (error) => {
        toast.error('Message failed to send');
        console.log(error.text);
      });
      e.target.reset();
  };

  return (
  <div className='minheight'>
    <section className={`bg-neutral-900/90 ${isTiny? 'mx-1' : 'mx-10'} rounded-md`}>
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-white">Contact Us</h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-neutral-400 sm:text-xl">Got a technical issue? Want to send feedback?</p>
        <form ref={form} onSubmit={(e) => sendEmail(e)} className="space-y-8">
          <div>
            <label className="block mb-2 text-sm font-medium text-neutral-300">Your email</label>
            <input type="email" id="email" name='user_email' className="shadow-sm border text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white shadow-sm-light" placeholder="email@email.com" required />
          </div>
          <div>
            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-neutral-300">Subject</label>
            <input type="text" id="subject" name='subject' className="block p-3 w-full text-sm rounded-lg border shadow-sm focus:ring-primary-500 focus:border-primary-500 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white shadow-sm-light" placeholder="Let us know how we can help you" required />
          </div>
          <div className="sm:col-span-2">
            <label className="block mb-2 text-sm font-medium text-neutral-400">Your message</label>
            <textarea id="message" name='message' rows={6} className="block p-2.5 w-full text-sm rounded-lg shadow-sm border-neutral-300 focus:ring-primary-500 focus:border-primary-500 bg-neutral-700 border-neutral-600 dark:placeholder-neutral-400 text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a message..."/>
          </div>
          <button type="submit" className="bg-neutral-900 border text-white hover:bg-neutral-700 p-2 rounded-md"><b>Send message</b></button>
        </form>
      </div>
    </section>
  </div>
  );
}

export default Contact;
