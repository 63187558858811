import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { FILTER_BY_SEARCH, selectFilteredProducts, SORT_PRODUCTS } from '../../../redux/slice/filterSlice';
import Search from '../../search/Search';
import ProductItem from '../productItem/ProductItem';


const ProductList = ({products}) => {
  const dispatch = useDispatch();
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })


  const [search, setSearch] = useState('');
  const filteredProducts = useSelector(selectFilteredProducts);

  const [sort, setSort] = useState('latest');


  useEffect(() => {
    dispatch(FILTER_BY_SEARCH({products, search}));
  }, [dispatch, products, search]);

  useEffect(() => {
    dispatch(SORT_PRODUCTS({products, sort}));
  }, [dispatch, products, sort]);

  return (
    <div id='product'>
      <div className={`flex justify-center ${isTiny ? 'mx-1' : 'mx-10'} mb-3 mt-1`}>
        <div className='shrink w-full'>
          <Search value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>

        <div className='flex-none ml-2'>
          <select value={sort} onChange={(e) => setSort(e.target.value)} className="drop-shadow-xl text-sm rounded-lg block p-3 bg-neutral-900/80 hover:bg-neutral-900 placeholder-white text-white">
            <option value="latest">Latest</option>
            <option value="lowest-price">Lowest Price</option>
            <option value="highest-price">Highest Price</option>
          </select>
        </div>

      </div>


      <div className='flex justify-center flex-wrap mx-8'>
        {filteredProducts.lenght == 0 ? (<h1>No Products Found</h1>)
        :
        (
          <>
            {filteredProducts.map((product) => {
              return(
                <div className='grow place-content-center' key={product.id}>
                  <ProductItem {...product} product={product} />
                </div>
              )}
            )}
          </>
        )
        }
      </div>



    </div>
  );
}

export default ProductList;
