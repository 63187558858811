import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { db } from '../firebase/config';

const useFetchDocument = (collectionName, documentID) => {
  const [document, setDocument] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getDocument = async () => {

    setIsLoading(true);

    const docRef = doc(db, collectionName, documentID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());//
      const obj = {
        id: documentID,
        ...docSnap.data()
      }
      setDocument(obj);
      setIsLoading(false);

    } else {
      toast.error('Not found');
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDocument();
  }, []);

  return { document, isLoading };
}

export default useFetchDocument;
