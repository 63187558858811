// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_KEY}`,
  authDomain: "portfolio-shop-10cce.firebaseapp.com",
  projectId: "portfolio-shop-10cce",
  storageBucket: "portfolio-shop-10cce.appspot.com",
  messagingSenderId: "991503972821",
  appId: "1:991503972821:web:e4f85df8f8fab01fb2a55c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
