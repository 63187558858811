import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FILTER_BY_CATEGORY, FILTER_BY_BRAND } from '../../../redux/slice/filterSlice';
import { selectProducts } from '../../../redux/slice/productSlice';
import jdmcollection from '../../../assets/nsx.gif';
import aicollection from '../../../assets/ai.gif';




const ProductFilter = () => {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [categories, setCategories] = useState('All');
  // eslint-disable-next-line
  const [brand, setBrand] = useState('All');
  const products = useSelector(selectProducts);

  const allCetegories = [
    'All',
    ...new Set(products.map((product) => product.category))
  ]


  // const allBrands = [ // Aka Collection
  //   ...new Set(products.map((product) => product.brand))
  // ]



  const filterProducts = (category) => {
    setCategories(category);
    dispatch(FILTER_BY_CATEGORY({products, category}))
  }

  const filterBrands = (brand) => {
    setBrand(brand);
    dispatch(FILTER_BY_BRAND({products, brand}))
  }

  return (
    <div className=''>
      
      <div>
        <div className='flex '>
          {allCetegories.map((category, index) => {
            return (
              <button className="drop-shadow-xl h-10 px-4 font-semibold rounded-md bg-neutral-900/80 hover:bg-neutral-900 text-white mr-1" key={index} onClick={() => filterProducts(category)}>
                {category}
              </button>
            )
          })}
          <button className="grow h-10 px-4 font-semibold rounded-md bg-neutral-900/80 text-white cursor-default"></button>

        </div>
      </div>

      <div>
        <div className='h-10 hover:h-40 mt-1 relative cursor-pointer' onClick={() => filterBrands('JDM Collection')}>
          <h2 className='text-white font-bold absolute text-2xl text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>JDM Collection</h2>
          <img src={jdmcollection} alt="JDM Collection" className='h-full w-full object-cover rounded-md' />
        </div>

        <div className='h-10 hover:h-40 mt-1 relative cursor-pointer' onClick={() => filterBrands('AI Collection')}>
          <h2 className='text-white font-bold absolute text-2xl text-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>AI Collection</h2>
          <img src={aicollection} alt="JDM Collection" className='h-full w-full object-cover rounded-md' />
        </div>
      </div>

    </div>
  );
}

export default ProductFilter;
