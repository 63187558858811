import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/config';



const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const registerUser = (e) => {
    e.preventDefault();
    
    if (password !== cpassword) {
      toast.error('Passwords do not match');
    }

    setIsLoading(true);

    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log(user);
      setIsLoading(false);
      toast.success('User created successfully');
      navigate('/login');
    })
    .catch((error) => {
      //const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(errorMessage);
      setIsLoading(false);
    });
  }

  return (
    <>
    <ToastContainer />
    {isLoading ? <Loader></Loader> : null}
    <div className='minheight pt-10'>
    <section className=''>
      <div className='m-6 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0'>

        <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-neutral-900/90 border-neutral-700'>
          <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
            <h1 className='text-xl font-bold leading-tight tracking-tight md:text-2xl text-white'>
              Sign Up
            </h1>

            <form onSubmit={registerUser} className='space-y-4 md:space-y-6'>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="name@email.com" required />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-white">Password</label>
                <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500" required />
              </div>
              <div>
                <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium text-white">Confirm Password</label>
                <input value={cpassword} onChange={(e) => setCPassword(e.target.value)} type="password" name="confirm_password" id="confirm_password" placeholder="••••••••" className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500" required />
              </div>

              <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Sign Up</button>
              <p className="text-sm font-light text-neutral-300">
                Alredy have an account?
                <Link to='/login' className="m-2 font-medium hover:underline dark:text-neutral-200">Log In</Link>
              </p>

            </form>

          </div>
        </div>

      </div>
    </section>
  </div>
  </>
  );
}

export default Register;
