import React from 'react';
import { Link } from 'react-router-dom';

const CheckoutSuccess = () => {
  return (
    <div className='minheight'>
      <div className='flex flex-col items-center justify-center h-screen pb-52'>
        <h1 className='text-3xl font-semibold text-white'>Checkout Successful</h1>
        <p className='text-white'>Thank you for your purchase!</p>

        <p className='text-white mt-4 text-center'>You can track your order progress by clicking on your name in navigation bar.</p>
        <p className='text-white'>Or by clicking <Link to='/order-history'><span className='underline'>My Orders</span></Link></p>
      </div>
    </div>
  );
}

export default CheckoutSuccess;
