import React, { useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { ADD_TO_CART, CALCULATE_SUMMARY, DECREASE_CART, REMOVE_FROM_CART, SAVE_URL, selectCartItems, selectCartTotalAmount, selectCartTotalQuantity } from '../../redux/slice/cartSlice';


const Cart = () => {
  const cartItems = useSelector(selectCartItems);
  const isHuge = useMediaQuery({ query: '(min-width: 1900px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 820px)' })
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })

  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const cartTotalAmount = useSelector(selectCartTotalAmount);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const increseCart = (cartItems) => {
    dispatch(ADD_TO_CART(cartItems)); // Add the new object to cartItems array
  }

  const decreaseCart = (cartItems) => {
    dispatch(DECREASE_CART(cartItems)); // Decrease the quantity of the product
  }

  const removeFromCart = (cartItems) => {
    dispatch(REMOVE_FROM_CART(cartItems)); // Remove the product from cart
  }

  useEffect(() => {
    dispatch(CALCULATE_SUMMARY(cartItems)); // Calculate the total amount and total quantity of items in the cart
    dispatch(SAVE_URL('')); // Clear the saved url
  }, [dispatch, cartItems]);



  const url = window.location.href;
  const {isLoggedIn} = useSelector((store) => store["auth"])

  const checkout = () => { // Redirect to checkout page if user is logged in, otherwise redirect to login page
    if (isLoggedIn) {
      navigate('/checkout-details');
    }
    else {
      dispatch(SAVE_URL(url));
      navigate('/login');
    }
  }


  return (
    <div className={`minheight ${isTiny? 'm-1' : 'm-10'}`}>
      <div className={isHuge ? 'mx-48' : ''}>
        
        {cartItems.length === 0 ? (
          <div className='flex flex-col items-center justify-center h-screen pb-52'>
            <h1 className='text-3xl font-semibold text-white'>Your cart is empty</h1>
            <p className='text-white'>Add some items to your cart</p>
          </div>
        ) : (
          <div className=''>
            <div className='bg-neutral-900 h-2 rounded-t-md'></div>


            {isMobile ?
            // Mobile version
            (<div>
              <table className="bg-neutral-900/95 w-full text-white text-center">
                <thead>
                  <tr className='bg-neutral-900'>
                    <th className='px-4 py-3 pb-5'>Image</th>
                    <th className='px-4 py-3 pb-5'>Product</th>
                    <th className='px-4 py-3 pb-5'>Price</th>
                    <th className='px-4 py-3 pb-5'></th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((cartItems, index) => {
                    const { id, name, size, price, imageURL, cartQuantity, category } = cartItems;
                    return (
                      <tr key={id+size} className='hover:bg-neutral-800'>
                        <td className='px-4 py-3 w-10'>
                          <img src={imageURL} alt={name} />
                        </td>
                        <td className='px-4 py-3'>
                          <p>{name}</p>
                          <p>Size: {category === 'Hats' ? 'OSFA' : size}</p>
                        </td>
                        <td className='px-4 py-3'>
                          <p>{price}€</p>
                          <div className='flex justify-center my-2'>
                            <button onClick={() => decreaseCart(cartItems)} className='px-2 py-1 bg-neutral-700/90 rounded-l-md'>-</button>
                            <p className='px-2 py-1 bg-neutral-900/90'>{cartQuantity}</p>
                            <button onClick={() => increseCart(cartItems)} className='px-2 py-1 bg-neutral-700/90 rounded-r-md'>+</button>
                          </div>
                          <p>{(price * cartQuantity).toFixed(2)}€</p>
                        </td>
                        <td className='px-4 py-3'>
                          <button onClick={() => removeFromCart(cartItems)} className='hover:bg-neutral-900 p-4 rounded-md'>
                            <FaTrashAlt color='red'></FaTrashAlt>
                          </button>
                        </td>

                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            ) : (
            // Desktop version
            <div>
              <table className="bg-neutral-900/95 w-full text-white text-center">
                <thead>
                  <tr className='bg-neutral-900'>
                    <th className='px-4 py-3 pb-5'>Image</th>
                    <th className='px-4 py-3 pb-5'>Name</th>
                    <th className='px-4 py-3 pb-5'>Size</th>
                    <th className='px-4 py-3 pb-5'>Price</th>
                    <th className='px-4 py-3 pb-5'>Quantity</th>
                    <th className='px-4 py-3 pb-5'>Total</th>
                    <th className='px-4 py-3 pb-5'></th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((cartItems, index) => {
                    const { id, name, size, price, imageURL, cartQuantity, category } = cartItems;
                    return (
                      <tr key={id+size} className='hover:bg-neutral-800'>
                        <td className='px-4 py-3 w-10'>
                          <img src={imageURL} alt={name} />
                        </td>
                        <td className='px-4 py-3'>
                          {name}
                        </td>
                        <td className='px-4 py-3'>
                          {category === 'Hats' ? 'OSFA' : size}
                        </td>
                        <td className='px-4 py-3'>
                          {price}€
                        </td>
                        <td className='px-4 py-3'>
                          <div className='flex justify-center'>
                            <button onClick={() => decreaseCart(cartItems)} className='px-2 py-1 bg-neutral-700/90 rounded-l-md'>-</button>
                            <p className='px-2 py-1 bg-neutral-900/90'>{cartQuantity}</p>
                            <button onClick={() => increseCart(cartItems)} className='px-2 py-1 bg-neutral-700/90 rounded-r-md'>+</button>
                          </div>
                        </td>
                        <td className='px-4 py-3'>
                          {(price * cartQuantity).toFixed(2)}€
                        </td>
                        <td className='px-4 py-3'>
                          <button onClick={() => removeFromCart(cartItems)} className='hover:bg-neutral-900 p-4 rounded-md'>
                            <FaTrashAlt color='red'></FaTrashAlt>
                          </button>
                        </td>

                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>)}



            <div className='w-full bg-neutral-900 p-10 rounded-b-lg'>
              <h1 className='text-2xl font-semibold text-white'>Cart Summary</h1>
              <div className='flex justify-between mt-5 text-white'>
                <p>Subtotal {cartTotalAmount.toFixed(2)}€</p>
                <button onClick={checkout} className='bg-white font-bold text-black p-4 rounded-md'>
                  Checkout
                </button>
              </div>
            </div>


          </div>
        )}


      </div>
    </div>
  );
}

export default Cart;
