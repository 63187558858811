import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { GrGoogle } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';

import Loader from '../../components/loader/Loader';

import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth } from '../../firebase/config';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';


const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  // Chose where to redirect the user after login
  const previousURL = useSelector((store) => store["cart"].previousURL);
  //console.log(previousURL);
  const redirectUser = () => {
    if (previousURL.includes('cart') ) {
      navigate('/cart');
    }
    else {
      navigate('/');
    }
  }




  // Login with email and password
  const loginUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      //const user = userCredential.user;
      setIsLoading(false);
      redirectUser();
    })
    .catch((error) => {
      //const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(errorMessage);
      setIsLoading(false);
    });
  }

  // Google Login
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = () => {

    signInWithPopup(auth, provider) 
    .then((result) => {
      //const user = result.user;
      redirectUser();

    }).catch((error) => {
      //const errorCode = error.code;
      const errorMessage = error.message;
      toast.error(errorMessage);
      
    });

  }


  return (
    <>
    <ToastContainer />
    {isLoading ? <Loader></Loader> : null}
    <div className='minheight pt-10'>
      <section className=''>
        <div className='m-6 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0'>

          <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-neutral-900/90 border-neutral-700'>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
              <h1 className='text-xl font-bold leading-tight tracking-tight md:text-2xl text-white'>
                Sign in to your account
              </h1>

              <form onSubmit={loginUser} className='space-y-4 md:space-y-6'>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
                  <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="name@email.com" required />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-white dark:text-white">Password</label>
                  <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500" required />
                </div>

                <div className="flex items-center justify-between">
                  <Link to='/reset'>
                    <p className="text-sm font-medium text-white hover:underline text-primary-500">Forgot password?</p>
                  </Link>
                </div>

                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Sign in</button>
                <button onClick={signInWithGoogle} className="flex items-center justify-center w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded">
                  
                  <div>
                    <GrGoogle className='' size={18}/>
                  </div>
                  <div className='pl-2'>
                    Sign in with Google
                  </div>
                  
                </button>
                <p className="text-sm font-light text-neutral-300">
                  Don't have an account yet?
                  <Link to='/register' className="m-2 font-medium hover:underline text-neutral-200">Sign up</Link>
                </p>

              </form>

            </div>
          </div>

        </div>
      </section>
    </div>
    </>
  );
}

export default Login;
