import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useFetchDocument from '../../../customHooks/useFetchDocument';
import Loader from '../../loader/Loader';
import ChangeOrderStatus from '../changeOrderStatus/ChangeOrderStatus';

const OrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  const { document, isLoading } = useFetchDocument('orders', id);

  useEffect(() => {
    setOrder(document);
  }, [document]);


  return (
    <div className='minheight bg-neutral-900/90'>
      {isLoading ? <Loader/> : 
      <div className='flex flex-wrap justify-center'>
        <div className='flex flex-wrap'>
          <div className='flex flex-col justify-center p-4'>
            <h1 className='text-3xl font-semibold text-white my-4'>Order details</h1>
            <p className='text-white'><b>Order ID:</b> {document.id}</p>
            <p className='text-white'><b>Order Amount:</b> {document.orderAmount}€</p>
            <p className='text-white'><b>Order Status:</b> {document.orderStatus}</p>
            <ChangeOrderStatus order={document} id={id} ></ChangeOrderStatus>
          </div>

          <div className='flex flex-col justify-center p-4'>
            <h1 className='text-3xl font-semibold text-white my-4'>Customer Details</h1>
            <p className='text-white'><b>Full Name: </b>{document.shippingAddress.name}</p>
            <p className='text-white'><b>Customer Email:</b> {document.customerEmail}</p>
            <p className='text-white'><b>Customer Phone:</b> {document.shippingAddress.phone}</p>
          </div>

          <div className='flex flex-col justify-center p-4'>
            <h1 className='text-3xl font-semibold text-white my-4'>Shipping details</h1>
            <p className='text-white'><b>Address (Line 1): </b>{document.shippingAddress.line1}</p>
            <p className='text-white'><b>Address (Line 2): </b>{document.shippingAddress.line2}</p>
            <p className='text-white'><b>City: </b>{document.shippingAddress.city}</p>
            <p className='text-white'><b>Country: </b>{document.shippingAddress.country}</p>
            <p className='text-white'><b>Postal Code: </b>{document.shippingAddress.postal_code}</p>
          </div>
        </div>

        <div className='flex flex-wrap'>
          {order.cartItems?.map((item, index) => {
          const { id, name, price, size, imageURL, cartQuantity } = item;
          
          return (
            
            <div key={index} className='w-96 bg-neutral-900 m-4 flex text-white hover:bg-neutral-900 rounded-md'>
              <img className='w-40 rounded-l-md' src={imageURL} alt={name} ></img>
              <div className='flex flex-col'>
                <p className='m-2'><b>Name: </b>{name}</p>
                <p className='m-2'><b>Size: </b>{size}</p>
                <p className='m-2'><b>Price: </b>{price}€</p>
                <p className='m-2'><b>Quantity: </b>{cartQuantity}</p>
              </div>
            </div>
          )
          })}
        </div>

      </div>}
    </div>
  );
}

export default OrderDetails;
