import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { SAVE_BILLING_ADDRESS, SAVE_SHIPPING_ADDRESS } from '../../redux/slice/checkoutSlice';

const initialAddressState = {
  name: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
  phone: '',
}

const CheckoutDetails = () => {
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shippingAddress, setShippingAddress] = useState({...initialAddressState})
  const [billingAddress, setBillingAddress] = useState({...initialAddressState})


  const handleShipping = (e) => {
    const {name, value} = e.target;
    setShippingAddress({...shippingAddress, [name]: value})
  }

  // const handleBilling = (e) => {
  //   const {name, value} = e.target;
  //   setBillingAddress({...billingAddress, [name]: value})

  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));
    dispatch(SAVE_BILLING_ADDRESS(billingAddress));
    navigate('/checkout')
  }

  return (
    <div className='minheight mx-1'>
      <div className='m-6 flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0'>
        <div className='w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-neutral-900/90 border-neutral-700'>
          <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
            <h1 className='text-xl font-bold leading-tight tracking-tight md:text-2xl text-white py-4'>
              Shipping Address
            </h1>

            <form onSubmit={handleSubmit} className='space-y-4 md:space-y-6'>

              <div>
                <label className="block mb-2 text-sm font-medium text-white">Full Name</label>
                <input type='text' name='name' value={shippingAddress.name} required onChange={(e) => handleShipping(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Address Line 1</label>
                <input type='text' name='line1' value={shippingAddress.line1} required onChange={(e) => handleShipping(e)}  className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Address Line 2 (Optional)</label>
                <input type='text' name='line2' value={shippingAddress.line2} onChange={(e) => handleShipping(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Country</label>
                <input type='text' name='country' value='Latvia' disabled required className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">City</label>
                <input type='text' name='city' value={shippingAddress.city} required onChange={(e) => handleShipping(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Postal/Zip code</label>
                <input type='text' name='postal_code' value={shippingAddress.postal_code} required onChange={(e) => handleShipping(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Phone</label>
                <div className='flex'>
                  <input value='+371' disabled required className="w-14 mr-1 border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
                  <input type='text' name='phone' value={shippingAddress.phone} required onChange={(e) => handleShipping(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
                </div>
              </div>


              {/* Billing */}
              {/* <h1 className='text-xl font-bold leading-tight tracking-tight md:text-2xl text-white py-4'>
              Billing Address
              </h1>

              <div>
                <label className="block mb-2 text-sm font-medium text-white">Full Name</label>
                <input type='text' name='name' value={billingAddress.name} required onChange={(e) => handleBilling(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Address Line 1</label>
                <input type='text' name='line1' value={billingAddress.line1} required onChange={(e) => handleBilling(e)}  className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Address Line 2 (Optional)</label>
                <input type='text' name='line2' value={billingAddress.line2} onChange={(e) => handleBilling(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Country</label>
                <input type='text' name='country' value='Latvia' disabled required onChange={(e) => handleBilling(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">City</label>
                <input type='text' name='city' value={billingAddress.city} required onChange={(e) => handleBilling(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Postal/Zip code</label>
                <input type='text' name='postal_code' value={billingAddress.postal_code} required onChange={(e) => handleBilling(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-white">Phone</label>
                <div className='flex'>
                  <input value='+371' disabled required className="w-14 mr-1 border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
                  <input type='text' name='phone' value={billingAddress.phone} required onChange={(e) => handleBilling(e)} className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-neutral-700 border-neutral-600 placeholder-neutral-400 text-white focus:ring-blue-500 focus:border-blue-500"/>
                </div>
              </div> */}


              {/* Submit */}
              <div className='w-full rounded-lg md:mt-0 sm:max-w-md xl:p-0'>
                <button type='submit' className='w-full items-center p-3 text-sm font-medium text-center text-white bg-neutral-900/80 rounded-lg hover:bg-neutral-900 my-1'>
                  Continue to Payment
                </button>
              </div>


            </form>
          </div>
        </div>



      </div>
    </div>
  );
}

export default CheckoutDetails;
