import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetchCollection from '../../customHooks/useFetchCollection'; // custom hook to fetch data from the Firebase
import { selectProducts, STORE_PRODUCTS } from '../../redux/slice/productSlice';
import ProductFilter from './productFilter/ProductFilter';
import ProductList from './productList/ProductList';
import Loader from '../loader/Loader';
import { useMediaQuery } from 'react-responsive';


const Product = () => {
  const isHuge = useMediaQuery({ query: '(min-width: 1900px)' })
  const isTiny = useMediaQuery({ query: '(max-width: 550px)' })


  const {data, isLoading} = useFetchCollection('products'); // My Custom Hook for getting data from firestore
  const products = useSelector(selectProducts);
  //console.log(products);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      STORE_PRODUCTS({
        products: data,
      })
    );
  }, [dispatch, data])


  return (
    <div className={isHuge ? 'mx-48' : ''}>
      <div className='grid grid-cols-12'>

        <div className={` ${isTiny ? 'mx-1' : 'mx-10'} col-span-12`}>
          <ProductFilter/>
        </div>

        <div className='col-span-12'>
          {isLoading ? <Loader></Loader> : null}
          <ProductList products={products} />
        </div>


      </div>
    </div>
  );
}

export default Product;
